<template>
  <div class="collapsible-list-header">
    <div class="title">{{ label }}</div>
    <div class="suffix">{{ labelSuffix }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    labelSuffix: {
      type: String,
    },
  },
};
</script>

<style>

</style>
