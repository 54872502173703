<template>
  <div class="collapsible-list">
    <CollapsibleListHeader
      v-if="header"
      :label="header.label"
      :labelSuffix="header.labelSuffix"
    />
    <CollapsibleListItem
      v-for="(item, i) in items"
      :key="i"
      :isInitOpen="item.isOpen"
      :labelDecoration="item.labelDecoration"
      :label="item.label"
      :labelSuffix="item.labelSuffix"
      :labelSuffixAccent="item.labelSuffixAccent"
      :value="item.value"
    />
  </div>
</template>

<script>
import './CollapsibleList.scss';
import CollapsibleListHeader from './components/CollapsibleListHeader.vue';
import CollapsibleListItem from './components/CollapsibleListItem.vue';

// TODO: Use list markup
export default {
  name: 'CollapsibleList',
  components: {
    CollapsibleListHeader,
    CollapsibleListItem,
  },
  props: {
    header: {
      type: Object,
    },
    items: {
      type: Array,
    },
  },
};
</script>
