<template>
  <button
    class="base-caret"
    :class="cssClasses"
    @click="$emit('click', $event)"
  >
    <img class="icon" src="./img/caret.svg" alt="Caret">
  </button>
</template>

<script>
import './BaseCaret.scss';

export default {
  name: 'BaseCaret',
  props: {
    isFlipped: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssClasses() {
      return {
        '-flipped': this.isFlipped,
      };
    },
  },
};
</script>
