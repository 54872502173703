<template>
  <div>
    <div class="container">
      <div
        v-if="priceHasLoadError"
      >
        <InfoBox variant="danger" :icon="icons.error">
          Nepodarilo sa zistiť cenu poistenia, zopakujte prosím požiadavku.
        </InfoBox>
        <BaseButton
          @click="update()"
        >
          Opakovať výpočet
        </BaseButton>
      </div>

      <div
        v-else
      >
        <div
          v-if="isPrefilledOfferFlow"
        >
          <InfoBox
            variant="success"
            size="small"
          >
            Rozhodnite sa, čo všetko chcete mať zahrnuté v poistení. <strong>Nákup dokončite na pár klikov.</strong>
          </InfoBox>
          <InfoBox
            v-if="shouldShowLeasedInfo"
            variant="success"
            size="small"
          >
            <div>
              Cena poistenia je vypočítaná pre bežné použitie auta, ktoré nie je zakúpené na leasing alebo úver. Máte auto na leasing alebo úver?
            </div>
            <BaseButton
              v-if="this.vehicle.leased === false"
              variant="secondary"
              size="small"
              @click="recountForLeasedVehicle"
              style="margin-top: 10px"
            >
              Prepočítať výšku pre auto na leasing/úver
            </BaseButton>
          </InfoBox>
        </div>

        <FormGroup
          label="Vyberte si balíček"
          :afterMessagePadding="false"
          :afterMessageArrowOffset="packageArrowOffset"
          :rules="[]"
        >
          <InputOptions
            v-model="productProps.package"
            variant="large"
            :options="optionsPackage"
          />
          <template #afterMessage>
            <CollapsibleList
              :header="{ label: 'Riziko', labelSuffix: 'Krytie' }"
              :items="packageItems"
            />
          </template>
        </FormGroup>

        <BaseButton
          v-if="!isDiscountCodeVisible"
          size="small"
          variant="secondary"
          :style="{ marginBottom: '30px' }"
          @click="isDiscountCodeVisible = true"
        >
          Máte zľavový kupón?
        </BaseButton>
        <FormGroup
          v-else
          label="Zľavový kupón"
        >
          <InputText
            v-model="discountCode"
          />
        </FormGroup>

        <FormGroup
          label="Zvoľte si frekvenciu platby"
          :rules="['requiredOption']"
          id="frekvencia"
        >
          <InputOptions
            :options="optionsFrequency"
            v-model.number="productProps.frequency"
          />
        </FormGroup>
      </div>
    </div>
  </div>
</template>

<script>
import currencyFormat from '@gds/util/currencyFormat';
import { faMobile, faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

import FormGroup from '@gds/components/FormGroup';
import CollapsibleList from '@gds/components/CollapsibleList';
import InputOptions from '@gds/components/InputOptions';
import InputText from '@gds/components/InputText';
import BaseButton from '@gds/components/BaseButton';
import InfoBox from '@gds/components/InfoBox';

import { mapGetters } from 'vuex';

const translateFrequency = (frequency) => ({
  1: 'ročne',
  2: 'polročne',
  4: 'štvrťročne',
  12: 'mesačne',
}[frequency]);

export default {
  components: {
    FormGroup,
    CollapsibleList,
    InputOptions,
    InputText,
    BaseButton,
    InfoBox,
  },
  data() {
    return {
      isDiscountCodeVisible: false,
      discountCode: '',
      icons: {
        mobilePhone: faMobile,
        error: faExclamationCircle,
      },
      shouldShowLeasedInfo: false,
    };
  },
  computed: {
    ...mapGetters([
      'calculation',
      'productProps',
      'prices',
      'vehicle',
      'isPrefilledOfferFlow',
      'priceHasLoadError',
      'priceIsLoading',
      'currentPrice',
    ]),
    optionsPackage() {
      const packages = ['M', 'L', 'XL'];
      const { frequency } = this.productProps;
      return packages.map((code) => {
        // eslint-disable-next-line arrow-body-style
        const match = this.prices.find((variant) => {
          return variant.frequency === this.productProps.frequency
            && variant.package === code;
        });
        const price = match?.prices.mainPrice;

        return {
          value: code,
          label: `Balík ${code}`,
          subLabel: match
            ? `${currencyFormat(price)} ${translateFrequency(frequency)}`
            : null,
          isLoading: !match,
        };
      });
    },
    packageArrowOffset() {
      return {
        M: 16.66,
        L: 50,
        XL: 83.33,
      }[this.productProps.package];
    },
    packageItems() {
      const activePackage = this.productProps.package;
      const onPackage = (packages) => packages.includes(activePackage);
      const getDecoration = (packages) => (onPackage(packages) ? 'check' : 'cross');
      return [
        {
          label: 'Škody na zdraví',
          labelSuffix: {
            M: '6,45 mil. €',
            L: '6,45 mil. €',
            XL: '10,00 mil. €',
          }[activePackage],
          labelSuffixAccent: onPackage(['XL']),
          value: 'Ak spôsobíte nehodu, kryjeme škody na zdraví všetkých osôb v cudzom vozidle vrátane vodiča, a tiež náklady pri usmrtení.',
          labelDecoration: getDecoration(['M', 'L', 'XL']),
        },
        {
          label: 'Škody na majetku',
          labelSuffix: {
            M: '1,3 mil. €',
            L: '6,00 mil. €',
            XL: '10,00 mil. €',
          }[activePackage],
          labelSuffixAccent: onPackage(['L', 'XL']),
          value: 'Ak spôsobíte nehodu, kryjeme škody na vozidlách a majetku poškodených osôb či obce.',
          labelDecoration: getDecoration(['M', 'L', 'XL']),
        },
        {
          label: 'Základné asistenčné služby',
          labelSuffix: 'áno',
          value: 'Dočerpanie paliva, náhradná doprava, odtiahnutie vozidla, oprava defektu či strata kľúčov? Máte k dispozícii naše asistenčné služby 24 hodín denne 7 dní v týždni. <a href="https://www.generali.sk/wp-content/uploads/2020/08/A_PZP__PREHLAD_KLASIK_20_fin.pdf " target="_blank">Zistite viac</a>',
          labelDecoration: getDecoration(['M', 'L', 'XL']),
        },
        {
          label: 'Úraz',
          labelSuffix: 'do 6 000 €',
          value: 'Poisťujeme úrazy vodiča a všetkých osôb v poistenom aute. Kryjeme ich do výšky 6 000 € pri rizikách – smrť úrazom a trvalé následky úrazom. A do výšky 1 000 € počas nevyhnutného liečenia.',
          labelDecoration: getDecoration(['L', 'XL']),
        },
        {
          label: 'Živel',
          labelSuffix: 'do 3 000 €',
          value: 'Vzťahuje sa na poškodenie vášho vozidla vplyvom živelnej udalosti, napr. povodne, záplavy, krupobitia, víchrice, požiaru, pádu stromu, zeminy, lavíny a iných. Spoluúčasť je 5 %, min. 100 €.',
          labelDecoration: getDecoration(['L', 'XL']),
        },
        {
          label: 'Výtlk',
          labelSuffix: 'do 1 000 €',
          value: 'Kryjeme vaše disky a pneumatiky proti zlému stavu vozovky. Ihneď po škode je nutné udalosť zdokumentovať našou asistenčnou službou. Platí iba na území SR. Spoluúčasť je 10 %.',
          labelDecoration: getDecoration(['L', 'XL']),
        },
        {
          label: 'Hlodavec',
          labelSuffix: 'do 3 000 €',
          value: 'Chránime vás pred hlodavcami, napr. pred prehryzením elektroinštalácie a pod. Spoluúčasť je 5 %, min. 100 €.',
          labelDecoration: getDecoration(['L', 'XL']),
        },
        {
          label: 'Stret so zverou',
          labelSuffix: 'do 4 000 €',
          value: 'Kryjeme vás aj pri kolízii so zverou. Ihneď po škode je nutné udalosť zdokumentovať našou asistenčnou službou. Platí iba na území SR. Spoluúčasť je 5 %, min. 100 €.',
          labelDecoration: getDecoration(['XL']),
        },
        {
          label: 'Pneumatiky',
          labelSuffix: 'do 150 €/ks',
          value: 'Uhradíme opravu či výmenu celej gumy v prípade defektu alebo vandalizmu jednej alebo viac pneumatík do 150 € za kus. Spoluúčasť závisí od opotrebovania pneu v čase jej poškodenia.',
          labelDecoration: getDecoration(['XL']),
        },
        {
          label: 'Asistencia Premium ',
          labelSuffix: 'áno',
          value: 'Dočerpanie paliva, náhradná doprava, odtiahnutie vozidla, oprava defektu či strata kľúčov? Máte k dispozícii naše nadštandardné asistenčné služby 24 hodín denne 7 dní v týždni. Garantované krytie jednej poistnej udalosti až do výšky 2 000 €. <a href="https://www.generali.sk/wp-content/uploads/2020/08/C_Premium_-_prehlad_fin.pdf" target="_blank">Zistite viac</a>',
          labelDecoration: getDecoration(['XL']),
        },
      ];
    },
    optionsFrequency: () => [
      { value: 1, label: 'Ročná' },
      { value: 2, label: 'Polročná' },
      { value: 4, label: 'Štvrťročná' },
      // { value: 12, label: 'Mesačná' },
    ],
  },
  methods: {
    update() {
      this.$store.dispatch('update', {
        apollo: this.$apollo,
        withPrices: true,
      });
    },
    tryProceed() {
      if (this.priceIsLoading) {
        // eslint-disable-next-line
        alert('Počkajte na načítanie');
      } else if (this.priceHasLoadError) {
        // eslint-disable-next-line
        alert('Načítajte najskôr cenu');
      } else {
        this.$v.validator.checkAndContinue(this);
      }
    },
    recountForLeasedVehicle() {
      this.vehicle.leased = true;
      this.update();
    },
  },
  created() {
    this.shouldShowLeasedInfo = this.vehicle.usageType === 'STANDARD' && this.vehicle.leased === false;
    this.$store.dispatch('sendMarketingTracking', 'lead');
    this.update();
  },
  watch: {
    'productProps.frequency': 'update',
    currentPrice(newValue) {
      if (newValue) {
        this.$sendToCI();
      }
    },
  },
};
</script>
