<template>
  <div
    class="collapsible-list-item"
    :class="cssClasses"
  >
    <header
      :class="cssClassesHeader"
      role="button"
      :aria-expandend="isOpen"
      @click="isOpen = !isOpen"
    >
      <img
        v-if="labelDecoration"
        class="decoration"
        :src="labelDecorationImage"
        :alt="labelDecoration"
      >
      <h3 class="title">{{ label }}</h3>
      <div
        v-if="labelSuffix"
        :key="labelSuffix"
        :class="{
          'suffix': true,
          'suffix-accent': labelSuffixAccent,
        }"
      >
        {{ labelSuffix }}
      </div>
      <BaseCaret class="caret" :isFlipped="isOpen" />
    </header>
    <CollapsibleSpace
      :isOpen="isOpen"
      :innerClass="cssClassesBody"
    >
      <CollapsibleList
        v-if="containsList"
        :items="value"
      />
      <span
        v-else
        v-html="value"
      />
    </CollapsibleSpace>
  </div>
</template>

<script>
import BaseCaret from '@gds/components/BaseCaret';
import CollapsibleSpace from '@gds/components/CollapsibleSpace';

export default {
  name: 'CollapsibleListItem',
  components: {
    BaseCaret,
    CollapsibleSpace,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  props: {
    isInitOpen: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    labelSuffix: {
      type: String,
    },
    labelSuffixAccent: {
      type: Boolean,
    },
    value: {
      type: [String, Array],
    },
    labelDecoration: {
      type: String,
      validator: (value) => ['check', 'cross', 'group'].includes(value),
    },
  },
  computed: {
    containsList() {
      return Array.isArray(this.value);
    },
    cssClasses() {
      return {
        '-open': this.isOpen,
        '-faded': (this.labelDecoration === 'cross' && !this.isOpen),
        '-contains-list': this.containsList,
      };
    },
    cssClassesHeader() {
      return {
        header: true,
        '-contains-list': !!this.containsList,
      };
    },
    cssClassesBody() {
      return {
        body: true,
        '-contains-list': !!this.containsList,
      };
    },
    labelDecorationImage() {
      if (this.labelDecoration) {
        // eslint-disable-next-line
        return require(`../img/${this.labelDecoration}.svg`);
      }
      return null;
    },
  },
  beforeCreate() {
    // Circular non-async load: https://stackoverflow.com/a/58875919
    // eslint-disable-next-line global-require
    this.$options.components.CollapsibleList = require('@gds/components/CollapsibleList').default;
  },
  created() {
    this.isOpen = this.isInitOpen;
  },
};
</script>
